/* Headings */

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  margin-bottom: 0;
  padding-bottom: 0;
}

h1 { 
  font-weight: 500;
  font-size: 2em;
  font-size: calc(1.1em + 1vw);
  letter-spacing: -0.02em;
  line-height: 1.25;
  margin: 1em 0 0.25em 0;
}
h2 {
  font-weight: 500;
  font-size: 1.4em;
  font-size: calc(1em + 0.3vw);
  letter-spacing: -0.015em;
  padding: 1.25em 0 0.25em 0;
  line-height: 1.3;
}
h3 { 
  font-weight: 500;
  font-size: 1.2em;
  font-size: calc(1em + 0.2vw);
  letter-spacing: -0.015em;
  line-height: 1.3;
  padding: 1.25em 0 0.25em 0;
}
h4 { 
  font-size: 1em;
  font-weight: 600;
  line-height: 1.3; 
  padding: 1em 0 0 0;
}
h5 {
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
  padding-bottom: 1em;
  letter-spacing: 0.15em;
  font-size: 1.5rem;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: var(--color-primary);
  text-decoration: none;
}  
h1 a, h1 a:visited, h1 a:link {
  color: var(--color-primary);
}
h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
  color: var(--color-muted);
  text-decoration: none;
}
