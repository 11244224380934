$media-phone: 600px;

:root {
  --font-family: -apple-system, BlinkMacSystemFont, "Inter", "IBM Plex Sans", Segoe UI, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  --font-mono: IBM Plex Mono, monospace;
  --border-radius: 4px;
  --font-small: 0.875em;
  --font-smaller: 0.8em;
  --font-large: 1.3em;
  --wrap-wide: 1000px;
  --wrap-normal: 37em;
  --input-width: 20em;

  @media (max-width: 860px) {
    --wrap-normal: 88vw;
    --wrap-wide: 100vw;
    --input-width: 100%;
    .wide {
      --border-radius: 0;
    }
  }
}

:root {
  --color-background-primary: #fefaf9;
  --color-background-secondary: rgba(0,0,0,0.05);
  --color-background-hover: hsl(7.6,73.3%,29.4%, 0.1);
  --color-selection: hsl(7.6,73.3%,29.4%, 0.1);

  --color-primary: hsl(0, 0%, 10%);
  --color-muted: rgba(90,80,70,.8);
  --color-faint: #ACA59B;

  --color-action: #822214; 
  --color-action-muted: hsl(7.6,73.3%,29.4%, 0.6);
  --color-action-faint: hsl(7.6,73.3%,29.4%, 0.35);


  /* --color-action: #147d82; 
  --color-action-muted: hsla(182, 84%, 32%, 0.6);
  --color-action-faint: hsla(182, 84%, 32%, 0.35); */

  --color-outline: rgba(0,0,0,0.1);
  --color-outline-hover: #d8d8d5;
  --color-outline-active: #c7c7c4;

  --color-highlight: rgba(255, 200, 0, 0.5);
}

.theme-dark {
  --color-background-primary: #2b2b2c;
  --color-background-secondary: rgba(255,255,255,0.05);
  --color-selection: hsla(35, 20%, 64%, .2);

  --color-primary: #dad6c6;
  --color-muted: hsla(35, 5%, 64%, .8);
  --color-faint: hsla(35, 5%, 64%, .5);

  --color-action: #d73821;
  
  /* --color-action: #62aba4; */


  --color-outline: hsla(35, 5%, 64%, .15);
  --color-outline-hover: hsla(35, 5%, 64%, .25);
  --color-outline-active: #c7c7c4;
}

