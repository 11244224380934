/* Padding */
.pa { padding:1rem;}
.pt { padding-top:1rem;}
.pr { padding-right:1rem;}
.pb { padding-bottom:1rem;}
.pl { padding-left:1rem;}

.ppa { padding:2rem;}
.ppt { padding-top:2rem;}
.ppr { padding-right:2rem;}
.ppb { padding-bottom:2rem;}
.ppl { padding-left:2rem;}

.pn1 { padding-top:2rem;}
.pn2 { padding-top:4rem;}
.pn3 { padding-top:6rem;}
.pn4 { padding-top:8rem;}

.ps1 { padding-bottom:2rem;}
.ps2 { padding-bottom:4rem;}
.ps3 { padding-bottom:6rem;}
.ps4 { padding-bottom:8rem;}

/* Spacing */
.st { margin-top:1rem;}
.sr { margin-right:1rem;}
.sb { margin-bottom:1rem;}
.sl { margin-left:1rem;}
.sst { margin-top:2rem;}
.ssr { margin-right:2rem;}
.ssb { margin-bottom:2rem;}
.ssl { margin-left:2rem;}

/* Spacing */
.mn1 { margin-top:2rem;}
.mn2 { margin-top:4rem;}
.mn3 { margin-top:6rem;}
.mn4 { margin-top:8rem;}

.ms1 { margin-bottom:2rem;}
.ms2 { margin-bottom:4rem;}
.ms3 { margin-bottom:6rem;}
.ms4 { margin-bottom:8rem;}